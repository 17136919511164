import Container from "@material-ui/core/Container"
import chunk from "lodash.chunk"
import React, { ChangeEvent, useEffect, useMemo } from "react"
import styled from "styled-components"
import { OverviewArray } from ".."
import useScrollToTop from "../../../../hooks/useScrollToTop"
import useSessionState from "../../../../hooks/useSessionState"
import { OverviewDataFragment } from "../../../../__generated__/graphql-gatsby"
import { useNavStateContext } from "../../../wrapRoot"
import Pagination from "../pagination"

interface IProps {
  data: OverviewArray
  sessionKey: string
  Component: React.FC<{ node: Maybe<OverviewDataFragment> }>
  maxWidth?: false | "xl" | "xs" | "sm" | "md" | "lg" | undefined
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`

const Wrapper: React.FC<IProps> = ({
  data,
  sessionKey,
  Component,
  maxWidth,
}) => {
  const [page, setPage] = useSessionState(`thha-grid-${sessionKey}`, 1)
  const scrollToTop = useScrollToTop()
  const lastNavState = useNavStateContext()

  useEffect(() => {
    if (lastNavState === "PUSH") {
      setPage(1)
    }
  }, [])

  const paginatedPosts: Array<OverviewArray> = useMemo(
    () => chunk(data, 9),
    [data]
  )

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    scrollToTop()
    setPage(value)
  }

  return (
    <Container maxWidth={maxWidth}>
      <Pagination
        count={paginatedPosts.length}
        page={page}
        onChange={handleChange}
      />

      <PageWrapper>
        {useMemo(
          () =>
            paginatedPosts[page - 1].map(node => (
              <Component key={node?.id} node={node} />
            )),
          [page]
        )}
      </PageWrapper>
      <Pagination
        count={paginatedPosts.length}
        page={page}
        onChange={handleChange}
      />
    </Container>
  )
}

export default Wrapper
